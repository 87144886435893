<template>
  <div :style='`width:${width}; height:${height}`'>
    <vue-apex-charts ref="chart" height="100%" type="bar" width="100%" :options="options" :series="series"></vue-apex-charts>
    <slot></slot>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import VisibilityMixin from '@/mixins/visibility'
import FilterBuilder, { EComponentTypes } from '../utils'

export default {
  name: 'a-chart',
  components: {
    VueApexCharts
  },
  inject: {
    getRegistryRecordId: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin, registryMixin, VisibilityMixin],
  props: {
    size: {
      frozen: true
    },
    align: {
      frozen: true
    },
    margin: {
      frozen: true
    },
    readonly: {
      frozen: true
    },
    hiddenCondition: {
      frozen: true
    },
    block: {
      frozen: true
    },
    isRequired: {
      frozen: true
    },
    wrapper: {
      frozen: true
    },
    alwaysActive: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    editorChart: {
      type: Object,
      editor: 'Chart',
      default: () => {
        return {
          type: 'plugin',
          colors: ['#69B3E7', '#3F95DA', '#0977CB', '#0058B9', '#0039A3', '#001489']
        }
      },
      options: { type: 'column' }
    },
    params: {
      type: String,
      description: 'Доп. параметры'
    },
    multiYaxis: {
      type: Boolean,
      description: 'Мульти Y ось',
      default: true
    },
    minimalYvalue: {
      type: Number,
      description: 'Минимальное значение серии'
    },
    width: {
      type: String,
      description: 'Ширина',
      default: '100%'
    },
    height: {
      type: String,
      description: 'Высота',
      default: '100%'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      loaderInstance: undefined,
      options: {
        title: {},
        colors: undefined,
        yaxis: {
          min: Number.isInteger(this.minimalYvalue) ? this.minimalYvalue : undefined,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              return (value || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              // return new Intl.NumberFormat('ru-RU').format(value)
            }
          }
        },
        chart: {
          stacked: false,
          animations: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        xaxis: {
          categories: [],
          tickPlacement: 'between'
        }
      },
      series: []
    }
  },
  computed: {
    dataFilters () {
      if (this.editorChart.type === 'plugin') {
        let filters = []
        if (this.filters) {
          this.filters.forEach((item) => {
            if (!item.type || item.type === 'field') {
              if (this.getModel()[item.attribute] && item.alias) {
                filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
              }
            } else if (item.type === 'constant' && item.alias) {
              filters.push(`${item.alias}=${item.attribute}`)
            }
          })
        }
        return filters
      } else {
        const builder = new FilterBuilder(
          this.filters,
          this.getModel(),
          this.$store,
          EComponentTypes.chart
        )

        const filters = builder.buildAsApiQl()

        if (filters.length > 0) {
          return {
            where: {
              and: [...filters]
            }
          }
        }

        return {}
      }
    }
  },
  watch: {
    dataFilters () {
      this.loadData()
    },
    editorChart: {
      handler (value) {
        this.options.title = Object.assign({}, this.options.title, value.title)
        this.options.legend = Object.assign({}, this.options.legend, value.legend)
        this.options.colors = value.colors
      },
      immediate: true
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  async mounted () {
    if ((this.editorChart.type === 'plugin' && !this.editorChart.plugin) ||
            (this.editorChart.type === 'extended_object' && !this.editorChart.extendObject)
    ) {
      return false
    }
    this.onVisibilityChange(() => {
      this.$nextTick(() => {
        if (this.$refs.chart && this.$refs.chart.chart && this.$refs.chart.$el.children[0].offsetHeight === 0) {
          this.$refs.chart.chart.update()
        }
      })
    })
    await this.loadData()
  },
  methods: {
    async loadData () {
      if (!this.loaderInstance) {
        this.loaderInstance = this.$loading({ target: this.$el })
      }
      if (this.editorChart.type === 'plugin') {
        let data = await this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.editorChart.plugin}?recordId=${this.getRegistryRecordId() || 0}&${this.dataFilters.join('&')}&${this.params || ''}`)
          .finally(() => {
            this.loaderInstance && this.loaderInstance.close()
            this.loaderInstance = undefined
          })
        if (data.data) {
          if (this.multiYaxis) {
            this.setYaxis(data.data.series)
          }
          this.$refs.chart.updateOptions({
            xaxis: {
              categories: data.data.categories
            }
          })
          this.series = data.data.series
        }
      } else if (this.editorChart.type === 'extend_object') {
        let data = await this.$http
          .post(`${this.$config.api}/datawarehouseservice/extended_object/${this.editorChart.extendObject}`, this.dataFilters, { hideNotification: true })
          .finally(() => {
            this.loaderInstance && this.loaderInstance.close()
            this.loaderInstance = undefined
          })
        let series = this.getExtendedObjectSeries(data.data)
        if (this.multiYaxis) {
          this.setYaxis(series)
        }
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: this.getExtendedObjectCategories(data.data)
          }
        })
        this.series = series
      } else {
        this.loaderInstance && this.loaderInstance.close()
        this.loaderInstance = undefined
      }
    },
    getExtendedObjectSeries (data) {
      let colors = []
      let opacity = []
      let answer = []
      this.editorChart.series.forEach((item) => {
        if (item.category.id) {
          let categories = {}
          data.forEach((row) => {
            if (typeof categories[row[item.category.name]] === 'undefined') {
              categories[row[item.category.name]] = []
            }
            categories[row[item.category.name]].push(row[item.field.name])
          })

          for (let category in categories) {
            if (categories.hasOwnProperty(category)) {
              answer.push({
                name: `${item.name} (${category})`,
                type: item.type,
                data: categories[category]
              })
            }
          }
          colors.push(...(item.category.color || []))
        } else {
          let object = {}
          object.name = item.name
          object.type = item.type
          object.data = data.map(row => row[item.field.name])
          colors.push(item.color)
          answer.push(object)
        }
        opacity.push(item.opacity)
      })
      this.$refs.chart.updateOptions({
        colors: colors
      })
      this.$set(this.options, 'colors', colors)
      this.$refs.chart.updateOptions({
        fill: {
          opacity: [...opacity]
        }
      })
      return answer
    },
    getExtendedObjectCategories (data) {
      return data.map(item => item[this.editorChart.categorie.name] + '').filter((value, index, self) => self.indexOf(value) === index)
    },
    setYaxis (data) {
      if (!data) {
        return false
      }
      let types = {
        line: { name: (data.find(item => item.type === 'line') || {}).name, isset: false, opposite: false },
        column: { name: (data.find(item => item.type === 'column') || {}).name, isset: false, opposite: false },
        area: { name: (data.find(item => item.type === 'area') || {}).name, isset: false, opposite: false }
      }
      if (!types.line.name && !types.column.name) {
        return false
      }
      let series = []
      data.forEach((item) => {
        let element = {}
        if (Number.isInteger(this.minimalYvalue)) {
          element.min = this.minimalYvalue
        }
        element.seriesName = types[item.type].name
        element.opposite = types[item.type].opposite
        if (types[item.type].isset) {
          element.show = false
        } else {
          types[item.type].isset = true
        }
        series.push(element)
      })
      this.$set(this.options, 'yaxis', series)
    }
  }
}
</script>

<style scoped>

</style>
